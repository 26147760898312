<template>
  <div class="container">
    <h1>Downloads</h1>
    <h3 class="mt-5 ">Riders Apps</h3>
    <div class="container">
      <div class="row d-flex justify-content-around">
        <div class="col-md-4 ">
          <el-card class="mt-2">
            
            
            <p class="d-flex flex-column justify-content-center mt-3">
              <a
                href="https://play.google.com/store/apps/details?id=com.rider.vite"
                target="_black"
                ><img class="download-icons pb-2" src="../assets/imgs/google-app.png" alt="..." srcset="">
              </a>
              <span class=" font-16 text-center mt-2"> to download for <b class="font-16"> Android</b> </span>
            </p>
          </el-card>
        </div>
        <el-card class="col-md-4  mt-2">
          
          <p class="d-flex flex-column justify-content-center mt-3">
            <a
              href="https://apps.apple.com/mu/app/vite-rider-app/id1549554140"
              target="_block"
              ><img class="download-icons pb-2" src="../assets/imgs/apple-store.svg" alt="..." srcset="">
            </a>
           <span class=" font-16 text-center mt-2"> to download for <b class="font-16"> IOS</b> </span>
          </p>
        </el-card>

        <el-card class="col-md-4  mt-2">
           
          <p class="d-flex flex-column justify-content-center mt-3">
            <a
              href="https://appgallery.huawei.com/#/app/C103464611"
              target="_blank"
              
              ><img class="download-icons pb-2" src="../assets/imgs/huawei-store.svg" alt="..." srcset="">
            </a>
            <span class=" font-16 text-center mt-2"> to download for <b class="font-16"> Huawei</b> </span>
          </p>
        </el-card>
      </div>
    </div>
    <hr>
    <h3 class="mt-4 ">Driver Apps</h3>

    <div class="row ml-1 ">
      <el-card class="col-md-4 mt-2">
        
        <p class="d-flex flex-column justify-content-center mt-3">
          <a
            href="https://play.google.com/store/apps/details?id=com.vite.driverapp"
            target="_black
          "
            ><img class="download-icons pb-2" src="../assets/imgs/google-app.png" alt="..." srcset="">
          </a>
         <span class=" font-16 text-center mt-2"> to download for <b class="font-16"> Android</b> </span>
        </p>
      </el-card>
    
      <el-card class="col-md-4 mt-2 ml-3">
        
        <p class="d-flex flex-column justify-content-center mt-3">
          <a
            href="https://appgallery.huawei.com/#/app/C104265931"
            target="_black"
            ><img class="download-icons pb-2" src="../assets/imgs/huawei-store.svg" alt="..." srcset="">
          </a>
         <span class=" font-16 text-center mt-2"> to download for <b class="font-16"> Huawei</b> </span>
        </p>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "downloads",
};
</script>

<style scoped>
.container {
  padding: 20px 0;
}
.row {
  padding-left: 1rem;
}
.container {
  font-size: 18px;
}
.download-icons{
  height: 90px;
  
  display: block;
  margin: auto;
  
}
.font-16{
  font-size: 25px;
}
/* .bg-icons{
  background-image: url("../assets/imgs/googleplaydownload.svg");
  height: 40px;
  width: 120px;
  background-position: center;
  
} */
</style>
